import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import { Banner } from 'components/Banner';
import { COUNTRIES } from 'common/constants';
import { Container } from 'react-bootstrap';
import { CountryContext } from 'CountryContext';
import { Footer } from 'components/Footer';
import { NavBar } from 'components/NavBar';
import { ROUTES } from 'navigation/constants';
import { SubmittedContext } from 'SubmittedContext';
import Why from 'screens/Why';
import { isValidCountry } from 'common/utils';

const About = lazy(() => import('screens/About'));
const TeenHome = lazy(() => import('screens/Teen'));
const ParentHome = lazy(() => import('screens/Parent'));
const PrivacyPolicy = lazy(() => import('screens/PrivacyPolicy'));
const TermsConditions = lazy(() => import('screens/TermsConditions'));

const getPathArray = (path) =>
  Object.values(COUNTRIES).map((isoCode) => `/${isoCode.toLowerCase()}${path}`);

const Routes = () => {
  const { navPathBuilder, userSwitchCountry } = useContext(CountryContext);
  const { submitted, setSubmitted } = useContext(SubmittedContext);

  const location = useLocation();

  useEffect(() => {
    const route = `/${location.pathname.split('/')[2]}`;
    const countryFromPath = location.pathname.split('/')[1];

    if (countryFromPath && isValidCountry(countryFromPath)) {
      userSwitchCountry(countryFromPath);
    }

    if (route === ROUTES.parents || (route === ROUTES.root && submitted)) {
      setSubmitted(false);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path={getPathArray(ROUTES.privacy)} component={PrivacyPolicy} />
        <Route exact path={getPathArray(ROUTES.terms)} component={TermsConditions} />
        <Route exact path={getPathArray(ROUTES.about)} component={About} />
        <Route exact path={getPathArray(ROUTES.why)} component={Why} />
        <Route exact path={getPathArray(ROUTES.parents)} component={ParentHome} />
        <Route exact path={getPathArray(ROUTES.root)} component={TeenHome} />
        {/* Handle invalid routes */}
        <Route component={() => <Redirect exact to={navPathBuilder(ROUTES.root)} />} />
      </Switch>
    </Suspense>
  );
};

const RootNavigator = () => (
  <Router>
    <Container fluid style={{ padding: 0 }}>
      <Banner
        title={[
          'Psst! Use the code ',
          <span style={{ fontWeight: 700 }}>ANA9E6179</span>,
          ' to join, and get a surprise 🎉',
        ]}
        displayIn={[COUNTRIES.INDIA]}
      />
      <NavBar />
      <Routes />
      <Footer />
    </Container>
  </Router>
);

export { RootNavigator };
