import { Col, Row } from 'react-bootstrap';
import 'components/PageHeader/index.scss';

export const PageHeader = ({ title, subtitle }) => (
  <Row>
    <Col className="text-center page-header">
      <p className="page-header__title mb-3">{title}</p>
      <p className="page-header__subtitle mx-5">{subtitle}</p>
    </Col>
  </Row>
);
