import { RootNavigator } from 'navigation/RootNavigator.jsx';
import { SubmittedContextProvider } from 'SubmittedContext.jsx';
import { CountryContextProvider } from 'CountryContext.jsx';

const App = () => (
  <CountryContextProvider>
    <SubmittedContextProvider>
      <RootNavigator />
    </SubmittedContextProvider>
  </CountryContextProvider>
);

export default App;
