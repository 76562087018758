import { COUNTRIES } from 'common/constants';

export const getWindowDimension = () => {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { width, height };
};

export const isValidCountry = (code) =>
  !!Object.values(COUNTRIES).find((country) => country.toLowerCase() === code.toLowerCase());

export const getPhoneNumberLength = (code) => {
  switch (code) {
    case COUNTRIES.INDIA:
      return 10;
    case COUNTRIES.MALAYSIA:
      return 9;
    case COUNTRIES.SINGAPORE:
    case COUNTRIES.THAILAND:
      return 8;
    default:
      return 10;
  }
};

export const getURLSearchParams = () => {
  const searchString = window.location.search;
  
  if (!searchString) {
    return null;
  }

  const searchParamsObj = {};
  const searchParams = new URLSearchParams(searchString);
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of searchParams) {
    searchParamsObj[key] = value;
  }

  return searchParamsObj;
};
