import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { ROUTE_TITLES } from 'navigation/constants';

const HelmetWrapper = ({ children }) => {
  const location = useLocation();
  const route = `/${location.pathname.split('/')[2]}`;

  
  return (
    <Helmet>
      <title>{ROUTE_TITLES[route]}</title>
      {children}
    </Helmet>
  );
};

const memoizedComponent = memo(HelmetWrapper);
export { memoizedComponent as HelmetWrapper };
