import { useState, useEffect, useContext } from 'react';
import { Navbar, Nav, Row } from 'react-bootstrap';
import { getWindowDimension } from 'common/utils';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { CountryContext } from 'CountryContext';
import { PersonaButton } from 'components/NavBar/PersonaButton';
import { IMAGES } from 'common/images';
import { COUNTRIES, PERSONAS } from 'common/constants';
import { ROUTES, LINKS } from 'navigation/constants';
import 'components/NavBar/index.scss';

export const NavBar = () => {
  const location = useLocation();
  const { navPathBuilder, country } = useContext(CountryContext);
  const [expanded, onToggle] = useState(false);

  useEffect(() => {
    onToggle(false);
  }, [location]);

  const displayExtraNavs = [ROUTES.about, ROUTES.why, ROUTES.terms, ROUTES.privacy].includes(
    `/${location.pathname.split('/')[2]}`,
  );

  return (
    <Navbar expanded={expanded} onToggle={onToggle} expand="lg" className="nav">
      <Navbar.Brand as={Link} to={navPathBuilder(ROUTES.root)}>
        <img src={IMAGES.NavLogo} className="nav__logo" alt="logo" />
      </Navbar.Brand>
      <Row noGutters className="justify-content-end flex-grow-1">
        {!displayExtraNavs && getWindowDimension().width <= 991 && (
          <PersonaButton
            persona={
              `/${location.pathname.split('/')[2]}` === ROUTES.parents
                ? PERSONAS.PARENT
                : PERSONAS.TEEN
            }
          />
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <div className={`${expanded ? 'is-active' : ''}`} id="hamburger">
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </Navbar.Toggle>
      </Row>
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-end flex-grow-0 expanded-navbar"
      >
        <Nav>
          <Nav.Link
            as={Link}
            activeClassName="nav__item--active"
            to={navPathBuilder(ROUTES.about)}
            className="nav__item mx-1"
          >
            About
          </Nav.Link>
          <Nav.Link
            as={Link}
            activeClassName="nav__item--active"
            to={navPathBuilder(ROUTES.why)}
            className="nav__item mx-1"
          >
            Why
          </Nav.Link>
          {country === COUNTRIES.INDIA && (
            <Nav.Link
              target="_blank"
              rel="noopener"
              href={LINKS.GOLD_MICROSITE}
              className="nav__item mx-1"
            >
              Auto Invest
            </Nav.Link>
          )}
          <Nav.Link
            target="_blank"
            rel="noopener"
            href={LINKS.COMMUNITY}
            className="nav__item mx-1"
          >
            Community
          </Nav.Link>
          <Nav.Link target="_blank" rel="noopener" href={LINKS.BLOGS} className="nav__item mx-1">
            Blogs
          </Nav.Link>
          {displayExtraNavs && (
            <>
              <Nav.Link
                as={Link}
                activeClassName="nav__item--active"
                to={navPathBuilder(ROUTES.parents)}
                className="nav__item mx-1"
              >
                I am a Parent
              </Nav.Link>
              <Nav.Link
                exact
                as={Link}
                activeClassName="nav__item--active"
                to={navPathBuilder(ROUTES.root)}
                className="nav__item mx-1"
              >
                I am a Teen
              </Nav.Link>
            </>
          )}
          {!displayExtraNavs && getWindowDimension().width >= 992 && (
            <div style={{ marginLeft: 12 }}>
              <PersonaButton
                persona={
                  `/${location.pathname.split('/')[2]}` === ROUTES.parents
                    ? PERSONAS.PARENT
                    : PERSONAS.TEEN
                }
              />
            </div>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
