import { createContext, useState, useEffect, useCallback } from 'react';
import { isValidCountry } from 'common/utils';
import { COUNTRIES } from 'common/constants';
import 'index.scss';

export const CountryContext = createContext({});
const KEY = 'at_m9j8Fuy8yCuSgLQbi8dcEJrWq5qSE';

export const CountryContextProvider = ({ children }) => {
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(true);

  const navPathBuilder = useCallback((path) => `/${country.toLowerCase()}${path}`, [country]);

  const userSwitchCountry = (newCountry) => {
    if (newCountry.toLowerCase() === country.toLowerCase()) {
      return;
    }

    setCountry(newCountry.toUpperCase());
  };

  const resolveCountry = async () => {
    try {
      const ip = await fetch('https://api.ipify.org/');
      const ipAddress = await ip.text();
      const ipstackString = `https://geo.ipify.org/api/v1?apiKey=${KEY}&ipAddress=${ipAddress}`;
      const response = await fetch(ipstackString);
      const jsonResponse = await response.json();

      const countryCode = jsonResponse.location.country;
      if (isValidCountry(countryCode)) {
        setCountry(countryCode);
      } else {
        setCountry(COUNTRIES.INDIA);
      }
    } catch (error) {
      setCountry(COUNTRIES.INDIA);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = window.location.pathname;
    if (isValidCountry(url.split('/')[1])) {
      setCountry(url.split('/')[1].toUpperCase());
      setLoading(false);
      return;
    }

    resolveCountry().then();
  }, []);

  if (loading || !country) {
    return (
      <div className="bg">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  return (
    <CountryContext.Provider
      value={{
        country,
        navPathBuilder,
        userSwitchCountry,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};
