import 'components/Banner/index.scss';
import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { CountryContext } from 'CountryContext';

export const Banner = ({ title, displayIn = [] }) => {
  const { country } = useContext(CountryContext);

  if(!displayIn.includes(country)) {
    return null;
  }

  return (
    <Row className="justify-content-center banner">
      <p className="banner__title">{title}</p>
    </Row>
  );
};
