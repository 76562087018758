import { useContext } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { CountryContext } from 'CountryContext';
import { FooterLinks } from 'components/Footer/FooterLinks';
import { IMAGES } from 'common/images';
import { LINKS, ROUTES } from 'navigation/constants';
import { COUNTRIES } from 'common/constants';
import 'components/Footer/index.scss';

export const Footer = () => {
  const { country, navPathBuilder } = useContext(CountryContext);

  return (
    <Container fluid className="footer">
      <Row noGutters>
        <Col xs={12} lg={6} className="no-pad footer__brand">
          <NavLink to={navPathBuilder(ROUTES.root)}>
            <LazyLoad height={200} offset={100} once>
              <Image src={IMAGES.Logo} alt="logo" className="logo" />
            </LazyLoad>
          </NavLink>
          <p className="footer__link footer__launching">
            {country === COUNTRIES.INDIA ? `Available on:` : 'Launching soon'}
          </p>
          <Row noGutters>
            {country === COUNTRIES.INDIA ? (
              <>
                <a target="_blank" rel="noopener" href={LINKS.PLAYSTORE}>
                  <LazyLoad height={200} offset={100} once>
                    <Image src={IMAGES.PlayStore} alt="Play Store" className="storeIcons mr-2" />
                  </LazyLoad>
                </a>
                <a target="_blank" rel="noopener" href={LINKS.APPSTORE}>
                  <LazyLoad height={200} offset={100} once>
                    <Image src={IMAGES.AppStore} alt="App Store" className="storeIcons" />
                  </LazyLoad>
                </a>
              </>
            ) : (
              <>
                <LazyLoad height={200} offset={100} once>
                  <Image src={IMAGES.PlayStore} alt="Play Store" className="storeIcons mr-2" />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once>
                  <Image src={IMAGES.AppStore} alt="App Store" className="storeIcons" />
                </LazyLoad>
              </>
            )}
          </Row>
        </Col>
        <FooterLinks />
      </Row>
    </Container>
  );
};
