/* eslint-disable max-len */
import AppStore from 'common/images/app-store.svg';
import PlayStore from 'common/images/play-store.webp';
import Arrow from 'common/images/arrow.webp';
import Netflix from 'common/images/netflix.webp';
import Uber from 'common/images/uber.webp';
import Coffee from 'common/images/coffee.webp';
import Haircut from 'common/images/haircut.webp';
import Movie from 'common/images/popcorn-cola.webp';
import Sketchbook from 'common/images/sketchbook.webp';
import Fifa from 'common/images/fifa.webp';
import Shirt from 'common/images/shirt.webp';
import Mastercard from 'common/images/mastercard.webp';
import DSS from 'common/images/dss.webp';
import Encryption from 'common/images/encryption.webp';
import Coins from 'common/images/coins.webp';
import Education from 'common/images/education.webp';
import SpeechBubble from 'common/images/speech-bubble.webp';
import Shield from 'common/images/shield.webp';
import Pencil from 'common/images/pencil.webp';
import MoneyBag from 'common/images/money-bag.webp';
import Settings from 'common/images/settings.webp';
import Notification from 'common/images/notification.webp';
import Card from 'common/images/card.webp';
import Plus from 'common/images/plus.webp';
import Tick from 'common/images/tick.webp';
import PlayRating from 'common/images/play-rating.webp';
import ArrowRight from 'common/images/chevron-right.svg';
import ArrowLeft from 'common/images/chevron-left.svg';
import Close from 'common/images/close.webp';
import TwitterWhite from 'common/images/twitter-white.webp';
import WhatsApp from 'common/images/whatsapp.webp';
import FacebookWhite from 'common/images/facebook.webp';
import NavLogo from 'common/images/logo.webp';

export const IMAGES = {
  NavLogo,
  Close,
  AppStore,
  PlayStore,
  Twitter:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615804263/yodaa_static/Website%20Assets/New%20Website/rhiwnvrzlto76cd6aro3.webp',
  Instagram:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615804263/yodaa_static/Website%20Assets/New%20Website/r0b4ubjojmopcnfx7qco.webp',
  Facebook:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615804263/yodaa_static/Website%20Assets/New%20Website/umvjxjhbkiqnoe553j2m.webp',
  Youtube:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615804263/yodaa_static/Website%20Assets/New%20Website/ic7pxk5zukbekkhun058.webp',
  Discord:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615804263/yodaa_static/Website%20Assets/New%20Website/htddc4pk5dedilixcncv.webp',
  Arrow,
  Netflix,
  Uber,
  Coffee,
  Haircut,
  Movie,
  Sketchbook,
  Fifa,
  Shirt,
  Mastercard,
  DSS,
  Encryption,
  Coins,
  Card,
  Education,
  MoneyBag,
  Notification,
  Pencil,
  Settings,
  Shield,
  SpeechBubble,
  Plus,
  ArrowRight,
  ArrowLeft,
  TwitterWhite,
  FacebookWhite,
  WhatsApp,
  Tick,
  PlayRating,
  Pizza: '',
  MoveTicket: '',
  Fuel: '',
  App: '',
  Pay: '',
  Chat: '',
  BS: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786436/yodaa_static/Website%20Assets/New%20Website/enaay0jhqhztutj7eu4s.webp',
  BFSI: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786435/yodaa_static/Website%20Assets/New%20Website/r9galkgxx7kmffhcps9u.webp',
  BW: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786436/yodaa_static/Website%20Assets/New%20Website/ahuolbinouo7qvb1q7m2.webp',
  AtlantisLogo:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786436/yodaa_static/Website%20Assets/New%20Website/lwoqibtnzwwm6vtddg6n.webp',
  Logo: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615788875/yodaa_static/Website%20Assets/New%20Website/tshxtrws0o6nryyci2z7.webp',
  YodaaApp:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786445/yodaa_static/Website%20Assets/New%20Website/pfngmbyrythwlq98byvw.webp',
  YodaaCard:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786445/yodaa_static/Website%20Assets/New%20Website/urcd6m1ln1uxo05twpdx.webp',
  AboutDesign:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786437/yodaa_static/Website%20Assets/New%20Website/sg7xsy9lmxavotp70cjl.webp',
  AboutEnjoy:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786437/yodaa_static/Website%20Assets/New%20Website/ku6tpavblv1t4vgwyxol.webp',
  AboutMission:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786436/yodaa_static/Website%20Assets/New%20Website/mebenyji6yyn1jyh8aeu.webp',
  ParentHeader:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786442/yodaa_static/Website%20Assets/New%20Website/wqisvidpaiy9cxifvtjz.webp',
  ParentCover:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786441/yodaa_static/Website%20Assets/New%20Website/vydo0doa3yqpw4tuo9ep.webp',
  AppRating:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786435/yodaa_static/Website%20Assets/New%20Website/zraghcusnnyjkkzpczjj.webp',
  TeenHeader:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615879007/yodaa_static/Website%20Assets/New%20Website/bhfpadrgfavflpo8taom.webp',
  TeenHeaderHalf:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786443/yodaa_static/Website%20Assets/New%20Website/ksunmu3h3swckz3awbdy.webp',
  TeenSec1:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786444/yodaa_static/Website%20Assets/New%20Website/vzpootu09a1ukua1yrmr.webp',
  TeenSec2:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786444/yodaa_static/Website%20Assets/New%20Website/p5btmozr9n730mrvcanh.webp',
  TeenSec3:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786444/yodaa_static/Website%20Assets/New%20Website/tnne1tveibflvlmrf2yg.webp',
  TeenSec4:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786444/yodaa_static/Website%20Assets/New%20Website/l5mlqqo66uqiyf5qiui5.webp',
  TeenSec5:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1629794244/yodaa_static/Website%20Assets/New%20Website/e57j6pau4nsa7yhg91eq.webp',
  S1: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786443/yodaa_static/Website%20Assets/New%20Website/rs2xggfdl9juyrjqnold.webp',
  S2: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786443/yodaa_static/Website%20Assets/New%20Website/ngqws8eatfaudizcan7c.webp',
  S3: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786443/yodaa_static/Website%20Assets/New%20Website/dzgv9ykmyntdtwkytaoz.webp',
  S4: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786443/yodaa_static/Website%20Assets/New%20Website/j52ftx3f8gxu8t5ybttt.webp',
  S5: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1619934980/yodaa_static/Website%20Assets/New%20Website/eq2hvrici3sv0eg0yr9y.webp',
  MS1: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786439/yodaa_static/Website%20Assets/New%20Website/eprmoenumkz1u7j8lzly.webp',
  MS2: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1617107096/yodaa_static/Website%20Assets/New%20Website/hh36dr8inmeonqcziwx1.webp',
  MS3: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1617107096/yodaa_static/Website%20Assets/New%20Website/nqxnegv27r8ikav6rq7k.webp',
  MS4: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1615786440/yodaa_static/Website%20Assets/New%20Website/aky6s9ksfm7p5lgzz7ph.webp',
  MS5: 'https://res.cloudinary.com/atlantiscapital/image/upload/v1619937361/yodaa_static/Website%20Assets/New%20Website/vhcgy508qsbiflf0ehrb.webp',
  Apple:
    'https://assets.website-files.com/5ff43bfe2aa4e5ff56a3e60d/60382703abc9e821d0424e0f_apple%201.png',
  Play: 'https://assets.website-files.com/5ff43bfe2aa4e5ff56a3e60d/603832d65fe7510e407c03e4_playstore%201.png',
  Stars:
    'https://assets.website-files.com/5ff43bfe2aa4e5ff56a3e60d/6038228a989b27407f42f2b1_Group%203920.png',
  ParentCoverHalf:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1615961585/yodaa_static/Website%20Assets/New%20Website/t8fvivysmxf4enyvdnrf.webp',
  PRAGUE_BADGE:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1629868830/yodaa_static/Website%20Assets/New%20Website/iywdo1jawkri1xafccwr.webp',
  PRAGUE_COVER:
    'https://res.cloudinary.com/atlantiscapital/image/upload/v1629870390/yodaa_static/Website%20Assets/New%20Website/w4jwho2rgpmkyc8lehis.webp',
};
