export const PERSONAS = {
  TEEN: 'teen',
  PARENT: 'parent',
};

export const COUNTRIES = {
  INDIA: 'IN',
  MALAYSIA: 'MY',
  THAILAND: 'TH',
  SINGAPORE: 'SG',
};

export const BASE_URL = 'https://site.yodaa.club/api';
