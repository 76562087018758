/* eslint-disable no-script-url */
export const ROUTES = {
  root: '/',
  parents: '/parents',
  about: '/about',
  why: '/why-yodaa',
  terms: '/terms',
  privacy: '/privacy',
};

export const ROUTE_TITLES = {
  [ROUTES.root]: 'Yodaa - The smart money app for teens',
  [ROUTES.parents]: 'Parents',
  [ROUTES.about]: 'About Us',
  [ROUTES.why]: 'Why Yodaa?',
  [ROUTES.privacy]: 'Privacy Policy',
  [ROUTES.terms]: 'Terms & Conditions',
};

export const SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/Yodaaclub-100222508311762',
  TWITTER: 'https://twitter.com/YodaaClub',
  DISCORD: 'https://discord.gg/jnMgcPxjqN',
  YOUTUBE: 'https://www.youtube.com/channel/UCPsHe4M6DI38Rv9noWZ-H4Q',
  INSTAGRAM: 'https://www.instagram.com/yodaa_club/',
};

export const LINKS = {
  ATLANTIS: 'https://www.atlantistech.co/',
  PLAYSTORE: 'https://play.google.com/store/apps/details?id=club.yodaa',
  APPSTORE: 'https://apps.apple.com/us/app/yodaa/id1515732544',
  BLOGS: 'https://blog.yodaa.club/',
  COMMUNITY: SOCIAL_LINKS.DISCORD,
  PRESS_KIT: 'javascript:void',
  ONELINK: 'http://onelink.to/fbeeg3',
  GOLD_MICROSITE: 'https://gold-microsite.webflow.io/',
  PRAGUE: 'https://offers.yodaa.club/',
  YODAA_PLUS: 'https://plus.yodaa.club',
  NAME_GENERATOR: 'https://pubg.yodaa.club/'
};

export const ARTICLES = {
  BS:
    'https://www.business-standard.com/content/press-releases-ani/yodaa-a-neo-bank-for-teens-launched-in-india-120103000610_1.html',
  BW:
    'http://www.businessworld.in/article/Yodaa-A-neo-bank-for-teens-launched-in-India/30-10-2020-337390',
  BFSI:
    'https://bfsi.economictimes.indiatimes.com/news/fintech/for-financial-literacy-teenagers-can-bank-on-yodaa/78948287',
};
