import { createContext, useState } from 'react';

export const SubmittedContext = createContext({});
export const SubmittedContextProvider = ({ children }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <SubmittedContext.Provider
      value={{
        submitted,
        setSubmitted,
      }}
    >
      {children}
    </SubmittedContext.Provider>
  );
};
