import { useContext, useEffect, useState } from 'react';
import { Row, Container, Col, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { CountryContext } from 'CountryContext';
import { PageHeader } from 'components/PageHeader';
import { Fade } from 'components/Fade';
import { IMAGES } from 'common/images';
import { ROUTES } from 'navigation/constants';
import { HelmetWrapper } from 'navigation/Helmet';
import 'screens/Why/index.scss';

export default () => {
  const [atTop, setAtTop] = useState(false);
  const { navPathBuilder } = useContext(CountryContext);

  const trackTop = () => {
    if (document.documentElement.scrollTop === 0) {
      setAtTop(true);
    }
  };

  useEffect(() => {
    trackTop();
    document.addEventListener('scroll', trackTop);
    return () => {
      document.removeEventListener('scroll', trackTop);
    };
  }, []);

  return (
    <Container fluid>
      <HelmetWrapper />
      <PageHeader
        title="Why are we building Yodaa?"
        subtitle="Raising a generation of financially aware teenagers for the new decade."
      />
      <Row className="why-content">
        <Col className="align-items-center no-pad">
          <Fade when={atTop}>
            <p className="why-content__para">
              Yodaa’s mission is simple: provide transparent, digitally native financial products
              that enable teens to make better financial decisions, right from the beginning of
              their financial journey.
              <br />
              <br />
              Yodaa is a teen banking platform: offering teens the cool factor of owning a card with
              their name on it. It gives them a first-hand understanding of personal finance by
              enabling them to manage their own money. It strives to be the fabric of financial
              well-being by teaching teens a crucial skill that has been missed out by the education
              system — personal finance. Yodaa enables teens to live the lifestyle they deserve.
            </p>
          </Fade>
          <Fade when={atTop}>
            <p className="why-content__para--bold">But, why is this important?</p>
            <p className="why-content__para">
              Imagine being told from a young age that when you are 20, you will become an
              astronaut. Until that age, you learn physics, astrobiology, and complex mathematics.
              At 20, you are given a large sum of money and an expense ledger. Then, you are asked
              to build a spaceship to carry you into space BUT within a certain budget.
              <br />
              <br />
              It should be a breeze since you studied for this, right? The curriculum seemed
              thorough enough?
              <br />
              <br />
              Sure, except for “trivial” things like budgeting and managing expenses.
              <br />
              <br />
              This is the situation many young adults find themselves in when they are suddenly
              responsible for their own finances. They have been trained in applied sciences and
              fine arts, but nobody taught them about wealth, the value of compound interest, and
              how much of an impact careful financial planning can have on their future financial
              freedom. We want to change that. We want to empower the coming generations by giving
              them the tools and liberty to learn and gain financial independence.
              <br />
              <br />
              That’s the gap Yodaa seeks to fill. And it’s a big one.
            </p>
          </Fade>
          <Fade when={atTop}>
            <p className="why-content__para--header">
              Yodaa aims to become the financial lifestyle app for 25 million teens in India and SE
              Asia.
            </p>
            <p className="why-content__para">
              Teenagers have very limited options to learn about financial literacy. Financial
              management is best learned through, well, managing finances. However, teenagers are
              usually stuck with a parent’s secondary card, with no visibility on how it works and
              no modern ways to manage their own allowances.
              <br />
              <br />
              - 78% of teens in India & SE Asia use cash to manage day-to-day expenses
              <br />
              - 45% of teens in India & SE Asia do not get pocket money, hence never learn money
              management or savings habits
              <br />
              - Teens are traditionally dependent on their parents’ cards for online or offline
              card-based purchases
              <br />
              - Teens do not have any formal exposure to financial education; this is not taught in
              schools, leading to potentially costly financial mistakes later in life.
              <br />‍
              <br />
              Yodaa’s development will occur in tandem with the needs of its teen community,
              essentially allowing them to BYOB (Build Your Own Bank).‍
              <br />
              <br />
              Through Yodaa, we’re attempting to empower teen users, by providing savings products
              that reward good financial decisions and by providing access to microcredit to
              inculcate sound borrowing mindsets. As our users navigate this newfound financial
              freedom, they will also have access to Yodaa’s curated learning platform, unlocking
              new benefits for taking the time to study the basics of personal finance. Instead of
              rewarding users for excessive spending, Yodaa’s rewards are linked to good financial
              awareness.
              <br />
              <br />
              The sleek, minimalist Yodaa Cards also have a virtual counterpart on the Yodaa app. We
              are tapping into the highly social aspects of teenagers’ lives by building in split
              payment requests and settlement systems into Yodaa, so that the fun of coffee catch
              ups is not mired by the hassle of settling bills.
            </p>
          </Fade>
          <Fade when={atTop}>
            <Row noGutters className="my-5">
              <Col xs={12} md={6} className="pr-md-3">
                <Image alt="Yodaa Card" src={IMAGES.YodaaCard} className="img-fluid" />
              </Col>
              <Col xs={12} md={6} className="pl-md-3 pt-5 pt-md-0">
                <Image alt="Yodaa App" src={IMAGES.YodaaApp} className="img-fluid" />
              </Col>
            </Row>
          </Fade>
          <Fade when={atTop}>
            <p className="why-content__para">
              Yodaa embraces both individualism and the inherently social lives of teenagers, while
              providing them with the tools and resources to learn about and practise personal
              finance. Yodaa is building a stellar banking experience to help teens achieve
              financial independence and knowledge at an earlier age, furthering our mission to
              create a ‘Bank of the Future’. A bank that gives you the tools to become smarter with
              your money — right from the start of your financial journey.
              <br />
              <br />
              Seems like a worthwhile cause, doesn’t it?
            </p>
            <p className="why-content__nav">
              {'Ready to try Yodaa? '}
              <NavLink to={navPathBuilder(ROUTES.root)} style={{ color: '#0EC0BF' }}>
                Start Here
              </NavLink>
            </p>
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};
