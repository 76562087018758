import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { CountryContext } from 'CountryContext';
import { IMAGES } from 'common/images';
import { ROUTES } from 'navigation/constants';
import { PERSONAS } from 'common/constants';

export const PersonaButton = ({ persona }) => {
  const { navPathBuilder } = useContext(CountryContext);

  return (
    <NavLink
      className="persona-button mr-2"
      to={
        persona === PERSONAS.PARENT ? navPathBuilder(ROUTES.root) : navPathBuilder(ROUTES.parents)
      }
    >
      <span className="persona-button__title">{`I'm a ${
        persona === PERSONAS.PARENT ? 'Teen' : 'Parent'
      }`}</span>
      <Image src={IMAGES.Arrow} alt="arrow-right" className="persona-button__arrow" />
    </NavLink>
  );
};
