/* eslint-disable jsx-a11y/anchor-is-valid */
import 'components/Footer/index.scss';

// import { Col, Image, Row } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { LINKS, ROUTES } from 'navigation/constants';

import { CountryContext } from 'CountryContext';
// import { IMAGES } from 'common/images';
// import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

export const FooterLinks = () => {
  const { navPathBuilder } = useContext(CountryContext);

  return (
    <Col xs={12} lg={6} className="no-pad">
      <Row noGutters>
        <Col md={3} xs={6} className="">
          <p className="footer__title">Yodaa</p>
          <Link to={navPathBuilder(ROUTES.about)} className="footer__link">
            About Us
          </Link>
          <p className="footer__link">Press Kit</p>
          <Link to={navPathBuilder(ROUTES.why)} className="footer__link">
            Why Yodaa?
          </Link>
        </Col>
        <Col md={3} xs={6} className="">
          <p className="footer__title">Explore</p>
          <a target="_blank" rel="noopener" href={LINKS.COMMUNITY} className="footer__link">
            Community
          </a>
          <a target="_blank" rel="noopener" href={LINKS.BLOGS} className="footer__link">
            Blogs
          </a>

           <a target="_blank" rel="noopener" href={LINKS.YODAA_PLUS} className="footer__link">
            Yodaa+
          </a>

           <a target="_blank" rel="noopener" href={LINKS.NAME_GENERATOR} className="footer__link">
            Name Generator
          </a>
        </Col>
        <Col md={3} xs={6} className="">
          <p className="footer__title">Legal</p>
          <Link to={navPathBuilder(ROUTES.privacy)} className="footer__link">
            Privacy Policy
          </Link>
          <Link to={navPathBuilder(ROUTES.terms)} className="footer__link">
            Terms & Conditions
          </Link>
        </Col>
        <Col md={3} xs={6} className="">
          <p className="footer__title">Contact Us</p>
          <a target="_blank" rel="noopener" href={LINKS.ATLANTIS} className="footer__link">
            {/* <LazyLoad height={200} offset={100} once>
              <Image src={IMAGES.AtlantisLogo} alt="atlantis" className="atlantis-logo" />
            </LazyLoad>
            <span> © 2021 </span> */}
          </a>
          <a href="mailto:hello@yodaa.club" className="footer__link">
            hello@yodaa.club
          </a>
        </Col>
      </Row>
    </Col>
  );
};
